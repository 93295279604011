import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button, Typography } from '@mui/material';
import Layout from '../components/Layout';
import imgBackground from '../images/3.jpg';
import ParalaxTitle from '../components/ParalaxTitle';
import SEO from '../components/seo';
import SocialShareButtons from '../components/SocialShareButtons';
import TitleBgFilled from '../components/TitleBgFilled';

const bookStates = {
  soon: 'Muy pronto',
  preorder: 'Preventa',
  published: 'Publicado',
};

function BookTrailer({ booktrailerUrl }: { booktrailerUrl: string }) {
  return (
    <Row>
      <Col className=" text-center">
        <TitleBgFilled className="mb-4" text="Book trailer" />
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            src={booktrailerUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Col>
    </Row>
  );
}

interface IBook {
  book_image: any;
  book_state: string;
  booktrailer_url: string;
  name: string;
  slug: string;
  synopsis: {
    synopsis: string;
  };
}

export default function Book({ pageContext }) {
  const book = pageContext.node as IBook;
  const imageData = getImage(book.book_image);
  return (
    <Layout>
      <SEO
        title={book.name}
        // description={book.description}
        // image={require(`../images/${book.img}`).default}
        path={`/libros/${book.slug}/`}
      />
      <ParalaxTitle text={book.name} imgBackground={imgBackground} />
      <Container>
        <Row className="justify-content-center">
          <div className="col-lg-3 my-auto">
            {book.book_state === bookStates.published && (
              <Button
                variant="contained"
                fullWidth
                href="https://tienda.babidibulibros.com/libro/dulcinea-la-princesa-que-hablaba-con-las-abejas_142442/"
                rel="noreferrer"
                target="_blank"
              >
                Adquirir en Babidi-bú
              </Button>
            )}
            <GatsbyImage image={imageData} alt="" />
          </div>
          <div className="col-lg-9 my-auto ">
            {book.book_state !== bookStates.soon && (
              <>
                <TitleBgFilled className="mb-4" text="Sinopsis" />
                <Typography mb={2} style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}>
                  {book.synopsis.synopsis}
                </Typography>
                <p>
                  <strong>Compartir: </strong>
                  <SocialShareButtons
                    size={40}
                    round
                    style={{ paddingRight: '5px' }}
                    url={`https://www.spbalcarcelart.com/libros/${book.slug}`}
                  />
                </p>
              </>
            )}
            {book.book_state === bookStates.soon && (
              <TitleBgFilled className="mb-4" text="¡Muy pronto!" />
            )}
          </div>
        </Row>
        {book.book_state === bookStates.published && (
          <BookTrailer booktrailerUrl={book.booktrailer_url} />
        )}
      </Container>
    </Layout>
  );
}
